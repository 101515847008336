import React from 'react';
import Slide from 'react-reveal/Slide';

import SEO from '../components/seo';

import { Menu, Item } from '../styles/styles.js';

const Bebidas = () => (
  <>
    <SEO title="Bebidas" />

    <Menu className="menu">
      <Slide left cascade duration={500}>
        <h1 className="menu-title">Bebidas</h1>
      </Slide>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Cerveja Skol Garrafa 600ml</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">15,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Cerveja Brahma Garrafa 600ml</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">15,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Cerveja Antarctica Garrafa 600ml</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">15,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Cerveja Heineken Garrafa 600ml</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">18,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Cerveja Original Garrafa 600ml</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">18,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Cerveja Spaten Garrafa 600ml</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">18,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Cerveja Budweiser Garrafa 600ml</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">18,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Long Neck</h4>

          <div className="item-price-wrapper">
            <p className="item-price"></p>
          </div>
        </div>

        <p className="item-desc">Consulte valor.</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Vinho (Taça)</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">12,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">H2O Limão 500ml</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">10,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">H2O Limoneto 500ml</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">10,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Refrigerante Lata 350ml</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">8,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Refrigerante KS</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">8,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Água Garrafinha</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">5,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Água com Gás Garrafinha</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">6,00</h4>
          </div>
        </div>
      </Item>

      <Slide left cascade duration={500}>
        <h1 className="menu-title">Sucos Lata</h1>
      </Slide>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Maracujá</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">8,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Pêssego</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">8,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Uva</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">8,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Suco de Laranja Garrafinha 330ml</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">8,00</h4>
          </div>
        </div>
      </Item>
    </Menu>
  </>
);

export default Bebidas;
